import { fetchCall } from 'api';
import { isString } from 'utils/validate';

/*
  Post tutoring session
*/  
export function submitTutoringSession({pairing, date, sessionLength, schoolSubjects, options, sessionFormat}){
  const opt = {
    method: "POST", 
    endpoint: "/portal/submit-tutoring-session",
    body: {
        pairing: pairing && (isString(pairing)? pairing: pairing.id), 
        date, 
        sessionLength, 
        schoolSubjects,
        options, 
        sessionFormat}
  }
  return fetchCall(opt)
}


/*
  Post recuperations
*/  
export function submitRecuperations({date, sessions}){
  const opt = {
    method: "POST", 
    endpoint: "/portal/submit-recuperations",
    body: {
        date, 
        sessions}
  }
  return fetchCall(opt)
}

/*
  Post maison des jeunes
*/  
export function submitCoachMeeting({date, sessions}){
  const opt = {
    method: "POST", 
    endpoint: "/portal/submit-coach-meeting",
    body: {
        date, 
        sessions}
  }
  return fetchCall(opt)
}

/*
  Post maison des jeunes
*/  
export function submitMaisonJeunes({date, sessions}){
  const opt = {
    method: "POST", 
    endpoint: "/portal/submit-maison-jeunes",
    body: {
        date, 
        sessions}
  }
  return fetchCall(opt)
}

/*
  Post tutorat prive
*/  
export function submitTutoratPrive({date, sessions}){
  const opt = {
    method: "POST", 
    endpoint: "/portal/submit-tutorat-prive",
    body: {
        date, 
        sessions}
  }
  return fetchCall(opt)
}

/*
  Search study sessions
*/
export function searchStudySessions({page, limit, orderBy, orderDirection, fromDate, toDate}){
  const opt = {
    method: "GET",
    endpoint: "/portal/grouped-study-sessions",
    query: {
      page,
      limit,
      orderBy,
      orderDirection,
      fromDate: fromDate && fromDate.toISOString(),
      toDate: toDate && toDate.toISOString()
    }
  };
  return fetchCall(opt);
}

/*
Update study session
*/
export function updateStudySession({id, lengthInMinutes, subjects}){
  const opt = {
    method: "POST",
    endpoint: `/portal/update-study-sessions/${id}`,
    body: {lengthInMinutes, subjects}
  };
  return fetchCall(opt);
}

/*
Delete study session
*/
export function deleteStudySession({id}){
  const opt = {
    method: "DELETE",
    endpoint: `/portal/delete-study-sessions/${id}`,
  };
  return fetchCall(opt);
}