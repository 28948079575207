import React, {useMemo} from 'react';

// Hooks
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearch } from 'hooks/useSearch';

// Components
import { SelectSchoolSubjectsForm, SelectSchoolSubjectsFormDropdown } from 'components/select/select-subjects';
import { Error, Heading } from 'components/core/typo';
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';

// API
import { searchSchoolSubjects } from 'api/utils';

// Context
import { AddStudySessionContext } from 'contexts/add-study-session';

export function SelectSchoolSubjects({value, setValue}){
    const { t } = useTranslation("add-study-session");
    const { studySession, updateStudySession } = useContext(AddStudySessionContext);
    const params = useMemo(()=>({usedForPreference: true}), []);
    const [subjects, {loading, error} ] = useSearch(searchSchoolSubjects, params);
    const update = setValue || updateStudySession;
    const controlledValue = value || (studySession && studySession.schoolSubjects) || [];

    return  <div>
                <Heading mediumTitle={t("select-subject.title")}
                        description={t("select-subject.description")}
                />
                {loading?
                <Skeleton className={"h-40"}/>:
                !subjects || subjects.length==0?
                    <EmptyState title={t("select-subject.empty.title")} description={t("select-subject.empty.description")}/>:
                    <SelectSchoolSubjectsForm 
                        values={controlledValue}
                        onClick={(value)=>update({type: 'toogle-school-subject', value})} 
                        subjects={subjects}/>
                }
                {error? <Error.Text {...error}/>: null}
            </div>
}

export function SelectSchoolSubjectsDropdown(){
    const { studySession, updateStudySession } = useContext(AddStudySessionContext);
    const params = useMemo(()=>({usedForPreference: true}), []);
    const [subjects ] = useSearch(searchSchoolSubjects, params);
    return <SelectSchoolSubjectsFormDropdown 
                value={studySession && studySession.schoolSubjects} 
                setValue={(value)=>updateStudySession({type: 'schoolSubjects', value})} 
                name={"schoolSubjects"}
                color="gray"
                subjects={subjects}/>
}


export function SelectSchoolSubjectsDropdownStudentBased({student}){
    const { studySession, updateStudySession } = useContext(AddStudySessionContext);
    const params = useMemo(()=>({usedForPreference: true}), []);
    const [subjects ] = useSearch(searchSchoolSubjects, params);
    
    return <SelectSchoolSubjectsFormDropdown 
                name={`schoolSubjects-${student.ni}`}
                color={(studySession.studentSchoolSubjects || {})[student.ni]? "warning":"whiteOutline"}
                value={(studySession.studentSchoolSubjects || {})[student.ni] || ( studySession && studySession.schoolSubjects) || []}
                setValue={schoolSubjects=>updateStudySession({type: 'set-student-school-subjects', value: {schoolSubjects, student}})}
                subjects={subjects}/>
}

