import React from "react";
import { useAPI } from "@/hooks/useAPI";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog";
import { updateStudySession } from "@/api/study_sessions";
import { SelectSessionLength } from "../add-study-session/select-length";
import { useTranslation } from "react-i18next";
import { Pencil, PencilOff } from "lucide-react";
import { SelectSchoolSubjects } from "../add-study-session/select-subjets";
import { parseWithTimeZone } from "@/utils/locale";
import { Button } from "../ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";


export function EditStudySession({ student, onChange, createdAt, length_in_minutes, subjects, id }){
    const {t} = useTranslation("common");
    const [,{execute: updateStudySessionMethod}] = useAPI(updateStudySession, {id}, {immediate:false});
    const onLengthChange = ({value})=>{
        updateStudySessionMethod({lengthInMinutes:value})
        onChange({id, length_in_minutes:value})
    };

    const onSubjectsChange = ({value})=>{
        // toggle the value ({slug, name}) from subjects
        const newSubjects = subjects.map((subject)=> subject.slug).includes(value.slug) ? subjects.filter((subject)=> subject.slug !== value.slug) : [...subjects, value]
        const subjectsSlugs = newSubjects.map((subject)=> subject.slug)
        updateStudySessionMethod({subjects:subjectsSlugs})
        onChange({id, subjects:newSubjects})
    }
    // period is a date (at midnight)
    // Count
    const todayDate = new Date()
    const today = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate());
    const parsedCreatedAt = parseWithTimeZone(createdAt);
    const createdAtDate = new Date(parsedCreatedAt.getFullYear(), parsedCreatedAt.getMonth(), parsedCreatedAt.getDate());
    
    const hoursBetweenNowAndPeriod = ( today-createdAtDate) / 1000 / 60 / 60;
    return <Dialog>
            <DialogTrigger asChild>
                <Button variant="ghost" className="p-0 w-8 h-8 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none">
                    <span className="sr-only">{t("edit-study-session.edit")}</span>
                    {hoursBetweenNowAndPeriod>24 ?
                    <TooltipProvider delayDuration={0}>
                        <Tooltip>
                        <TooltipTrigger >
                            <PencilOff className="h-4 w-4 opacity-30" />
                        </TooltipTrigger>
                        <TooltipContent className="text-sm  max-w-[200px] whitespace-prewrap">
                            <p className="text-sm  max-w-[200px] whitespace-prewrap">{t("edit-study-session.warning-too-late")}</p>
                        </TooltipContent>
                     </Tooltip>
                    </TooltipProvider>
                    :
                    <Pencil className="h-4 w-4" />
                    }
                </Button>
            </DialogTrigger>
            <DialogContent className="w-full max-w-[1000px]">
                <DialogHeader>
                <DialogTitle>{student && student.name}</DialogTitle>
                <DialogTitle>{t("edit-study-session.title")}</DialogTitle>
                    <DialogDescription>
                        {t("edit-study-session.description")}
                    </DialogDescription>
                </DialogHeader>
                {/* {} */}
                {hoursBetweenNowAndPeriod>24 ?
                    <p className="text-sm text-red-500">{t("edit-study-session.warning-too-late")}</p>
                    :
                    <>
                    <SelectSessionLength value={length_in_minutes} setValue={onLengthChange}/>
                <SelectSchoolSubjects
                            value={subjects}
                                setValue={onSubjectsChange}
                            />
                            </>
                }
                
            </DialogContent>
    </Dialog>
}